.project {
  /* border: 3px solid white; */
  padding: 1rem;
  margin-bottom: 3rem;
  max-width: 858px;
  margin: 3rem auto;
}

.projectImage {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.projectInfo {
  /* border: 1px solid #f1d42d; */
  background: #112240;
  background: var(--clr-grey-3);
  padding: 1rem;
}

.projectInfo span {
  color: var(--clr-red-light);
  font-size: 0.9rem;
  font-weight: bold;
}

.projectInfo h3 {
  font-size: 1.3rem;
  margin-top: 0.5rem;
}

.textBox {
  padding: 0.5rem 0;
  font-size: 0.9rem;
}

.textBox button {
  background: transparent;
  border: transparent;
  color: var(--clr-red-light);
  cursor: pointer;
  font-size: 0.9rem;
  margin-left: 0.5rem;
}

.projectTechstack {
  /* border: 1px solid #fff; */
  margin-bottom: 1.2rem;
}

.projectTechstack ul {
  display: flex;
  justify-content: start;
}

.projectTechstack li {
  /* border: 1px solid #fff; */
  margin-right: 0.8rem;
  padding: 0.17rem 0.4rem;
  font-size: 0.8rem;
}

.projectTech {
  background: #dae2ec;
  color: #0f1524;
  padding: 0.1rem 0.3rem;
  margin-right: 0.8rem;
  border-radius: 5px;
  font-size: 1.1rem;
}

.projectIcons {
  margin-top: 0.8rem;
  display: flex;
  /* justify-content: end; */
  align-items: center;
  flex-wrap: wrap;
}

.projectIcons a {
  margin-right: 0.8rem;
}

.projectIcons svg {
  color: var(--clr-grey-5);
  font-size: 1.2rem;
  /* color: red; */
}

.projectIcons svg:hover {
  color: var(--clr-red-light);
  transition: var(--transition);
}

@media screen and (min-width: 789px) {
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .project {
    flex: 0 0 calc(48% - 1rem);
  }
}

@media screen and (min-width: 1072px) {
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .project {
    flex: 0 0 calc(31% - 1rem);
  }
}

/* @media screen and (min-width: 992px) {
  .project {
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
  }
  .projectImage,
  .projectInfo {
    grid-row: 1/1;
  }

  .projectImage {
    grid-column: 1 / span 8;
    transition: all 0.3s linear;
  }

  .projectImage:hover {
    grid-column: 1 / span 8;
    transition: all 0.3s linear;
    z-index: 2;
  }

  .projectInfo {
    grid-column: 5/12;
    z-index: 1;
    text-align: right;
  }

  .projectTechstack {
    justify-content: end;
  }

  .projectTechstack li {
    font-size: 0.9rem;
  }

  .project:nth-of-type(even) .projectImage {
    grid-column: 5 / -1;
    grid-row: 1 / 1;
  }
  .project:nth-of-type(even) .projectInfo {
    grid-column: 2 / span 7;
    grid-row: 1 / 1;
    text-align: left;
  }
  .project:nth-of-type(odd) .projectTechstack ul {
    justify-content: end;
  }
  .project:nth-of-type(odd) .projectIcons {
    justify-content: end;
  }

  .textBox {
    font-size: 1rem;
  }
} */
