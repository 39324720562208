.heroSection {
  /* border: 1px solid red; */
  padding: 0 1rem;
}

.heroSection p {
  /* border: 1px solid red; */
  max-width: 24rem;
  color: var(--clr-grey-5);
}

.btn {
  border: transparent;
  padding: 0.8rem 1.6rem;
  background-color: var(--clr-red-light);
  color: #fff;
  font-size: 0.92rem;
  font-weight: bold;
  letter-spacing: var(--spacing);
  border-radius: 5px;
  margin-top: 1rem;
  cursor: pointer;
}

.btn:hover {
  transition: var(--transition);
  background: none;
  border: 1px solid #fff;
}

.sidebarIcons {
  /* border: 1px solid #fff; */
  display: flex;
  justify-content: start;
}

.sidebarIcons a {
  /* border: 1px solid white; */
  font-size: 1.4rem;
  /* margin: 1rem 0.5rem; */
  margin-right: 0.8rem;
  color: #fff;
  transition: all 0.3s linear;
}

.sidebarIcons a:hover {
  color: var(--clr-red-light);
}

@media screen and (max-width: 879px) {
  .heroIcons {
    display: none;
  }
}
