.title {
  /* border: 1px solid red; */
  margin: 4rem auto 4.8rem auto;
  text-align: center;
  color: var(--clr-grey-5);
}

.title-underline {
  width: 5rem;
  height: 0.25rem;
  margin-bottom: 1.25rem;
  /* margin-bottom: 5rem; */
  background: var(--clr-grey-5);
  margin-left: auto;
  margin-right: auto;
}
