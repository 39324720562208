.footer {
  /* border: 1px solid #fff; */
  padding: 1.6rem;
  background: var(--clr-red-light);
  background: var(--clr-grey-3);
}

.footerCenter {
  /* border: 1px solid red; */
}

.footerCenter p {
  text-align: center;
  margin: 0.8rem;
}

.sidebarIcons {
  /* border: 1px solid #fff; */
  display: flex;
  justify-content: center;
}

.sidebarIcons a {
  /* border: 1px solid white; */
  font-size: 1.2rem;
  margin: 0.8rem 0.4rem;
  color: #fff;
  transition: all 0.3s linear;
}

.sidebarIcons a:hover {
  color: var(--clr-red-light);
}

.social-icons a:hover {
  color: var(--clr-primary-1);
}

.myname {
  color: var(--clr-red-light);
}
